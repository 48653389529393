import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService
    ) {}

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     const token = this.authService.getAccessToken();
    //     const applicationId = localStorage.getItem('application_id') || '';
    //     const userId =  localStorage.getItem('UserID') || '';
    //     const unitId = sessionStorage.getItem('unitId') || '';  // Get unit_id (can be dynamic)

    //     // Add token, application_id, userId, and unitId to headers
    //     if (this.authService.isLoggedIn() && token) {
    //         request = this.addToken(request, token, applicationId, userId, unitId);
    //     } else {
    //         request = this.addApplicationIdUserIdAndUnitId(request, applicationId, userId, unitId);
    //     }

    //     return next.handle(request).pipe(
    //         catchError(error => {
    //             if (error instanceof HttpErrorResponse && error.status === 401) {
    //                 const originalUrl = this.router.url;
    //                 if (!this.refreshTokenInProgress) {
    //                     this.refreshTokenInProgress = true;
    //                     this.accessTokenSubject.next(null);

    //                     return this.authService.getNewAccessToken().pipe(
    //                         switchMap(newToken => {
    //                             if (newToken) {
    //                                 this.accessTokenSubject.next(newToken);
    //                                 this.refreshTokenInProgress = false;
    //                                 this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //                                     this.router.navigate([originalUrl], { relativeTo: this.route });
    //                                 });
    //                                 request = this.addToken(request, newToken, applicationId, userId, unitId);
    //                                 return next.handle(request);
    //                             }
    //                             this.authService.logout();
    //                             return throwError('Unable to refresh token');
    //                         }),
    //                         catchError(err => {
    //                             this.refreshTokenInProgress = false;
    //                             this.authService.logout();
    //                             return throwError(err);
    //                         })
    //                     );
    //                 } else {
    //                     return this.accessTokenSubject.pipe(
    //                         switchMap(token => {
    //                             if (token) {
    //                                 request = this.addToken(request, token, applicationId, userId, unitId);
    //                                 return next.handle(request);
    //                             }
    //                             this.authService.logout();
    //                             return throwError('No token available');
    //                         }),
    //                         catchError(err => {
    //                             this.authService.logout();
    //                             return throwError(err);
    //                         })
    //                     );
    //                 }
    //             } else {
    //                 return throwError(error);
    //             }
    //         })
    //     );
    // }

    // // Helper function to add token, application_id, userId, and unitId to the request
    // private addToken(request: HttpRequest<any>, token: string, applicationId: string, userId: string, unitId: string): HttpRequest<any> {
    //     let headers: { [key: string]: string } = {
    //         Authorization: `JWT ${token}`,
    //     };

    //     if (applicationId) {
    //         headers['application_id'] = applicationId;  // Add application_id only if it's present
    //     }

    //     if (userId) {
    //         headers['user_id'] = userId;  // Add UserID only if it's present
    //     }

    //     if (unitId) {
    //         headers['unit_id'] = unitId;  // Add unit_id only if it's present
    //     }

    //     return request.clone({
    //         setHeaders: headers
    //     });
    // }

    // // Helper function to add application_id, userId, and unitId to the request if available
    // private addApplicationIdUserIdAndUnitId(request: HttpRequest<any>, applicationId: string, userId: string, unitId: string): HttpRequest<any> {
    //     let headers: { [key: string]: string } = {};

    //     if (applicationId) {
    //         headers['application_id'] = applicationId;  // Add application_id header only if it's present
    //     }

    //     if (userId) {
    //         headers['UserID'] = userId;  // Add UserID header only if it's present
    //     }

    //     if (unitId) {
    //         headers['unit_id'] = unitId;  // Add unit_id header only if it's present
    //     }

    //     return request.clone({
    //         setHeaders: headers
    //     });
    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getAccessToken();
        const applicationId = sessionStorage.getItem('application_id') || '';
        const userId = localStorage.getItem('UserID') || '';
        const unitId = sessionStorage.getItem('ct_unitId') || ''; // Get unit_id (can be dynamic)
    
        // Check if the request URL matches the two specific API calls
        const excludeApplicationIdApis = [
            '/v1/wm/get_cards_details',
            '/v1/wm/get_top_facilities_consumptions_deviation',
            '/v1/wm/card_line_chart',
            '/v1/wm/monthly_report'
        ];
    
        const shouldExcludeApplicationId = excludeApplicationIdApis.some(api => request.url.includes(api));
    
        // Add token, application_id, userId, and unitId to headers
        if (this.authService.isLoggedIn() && token) {
            request = this.addToken(request, token, applicationId, userId, unitId, shouldExcludeApplicationId);
        } else {
            request = this.addApplicationIdUserIdAndUnitId(request, applicationId, userId, unitId, shouldExcludeApplicationId);
        }
    
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    const originalUrl = this.router.url;
                    if (!this.refreshTokenInProgress) {
                        this.refreshTokenInProgress = true;
                        this.accessTokenSubject.next(null);
    
                        return this.authService.getNewAccessToken().pipe(
                            switchMap(newToken => {
                                if (newToken) {
                                    this.accessTokenSubject.next(newToken);
                                    this.refreshTokenInProgress = false;
                                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                        this.router.navigate([originalUrl], { relativeTo: this.route });
                                    });
                                    request = this.addToken(request, newToken, applicationId, userId, unitId, shouldExcludeApplicationId);
                                    // request = this.addToken(request, newToken, applicationId, userId, unitId, shouldExcludeApplicationId);
                                    return next.handle(request);
                                }
                                this.authService.logout();
                                return throwError('Unable to refresh token');
                            }),
                            catchError(err => {
                                this.refreshTokenInProgress = false;
                                this.authService.logout();
                                return throwError(err);
                            })
                        );
                    } else {
                        return this.accessTokenSubject.pipe(
                            switchMap(token => {
                                if (token) {
                                    request = this.addToken(request, token, applicationId, userId, unitId, shouldExcludeApplicationId);
                                    return next.handle(request);
                                }
                                this.authService.logout();
                                return throwError('No token available');
                            }),
                            catchError(err => {
                                this.authService.logout();
                                return throwError(err);
                            })
                        );
                    }
                } else {
                    return throwError(error);
                }
            })
        );
    }
    
    // Helper function to add token, application_id, userId, and unitId to the request
    private addToken(
        request: HttpRequest<any>, 
        token: string, 
        applicationId: string, 
        userId: string, 
        unitId: string, 
        excludeApplicationId: boolean
    ): HttpRequest<any> {
        let headers: { [key: string]: string } = {
            Authorization: `JWT ${token}`,
        };
    
        if (!excludeApplicationId && applicationId) {
            headers['application_id'] = applicationId; // Add application_id only if it's not excluded
        }
    
        if (userId) {
            headers['user_id'] = userId; // Add UserID only if it's present
        }
    
        if (unitId) {
            headers['unit_id'] = unitId; // Add unit_id only if it's present
        }
    
        return request.clone({
            setHeaders: headers
        });
    }
    
    // Helper function to add application_id, userId, and unitId to the request if available
    private addApplicationIdUserIdAndUnitId(
        request: HttpRequest<any>, 
        applicationId: string, 
        userId: string, 
        unitId: string, 
        excludeApplicationId: boolean
    ): HttpRequest<any> {
        let headers: { [key: string]: string } = {};
    
        if (!excludeApplicationId && applicationId) {
            headers['application_id'] = applicationId; // Add application_id header only if it's not excluded
        }
    
        if (userId) {
            headers['UserID'] = userId; // Add UserID header only if it's present
        }
    
        if (unitId) {
            headers['unit_id'] = unitId; // Add unit_id header only if it's present
        }
    
        return request.clone({
            setHeaders: headers
        });
    }
    
}
